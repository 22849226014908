<template>
  <div class="ToggleValues">
    <div class="ToggleValuesLabel">
      {{ label }}
    </div>
    <div class="ToggleValuesSwitch">
      <a class="button activeButton" v-for="(i, index) in currentValues" :key="index" @click="setValue(i)" :active="currentValue === i.value">
        {{ i.label }}
      </a>
    </div>
    <input type="hidden" :name="name" :value="currentValue" />
  </div>
</template>

<script>
export default {
  props: ["values", "value", "label", "name"],
  data: function() {
    return {
      currentValue: this.value,
      currentValues: this.values || [],
    };
  },
  methods: {
    setValue: function(i) {
      this.currentValue = i.value;
      this.$emit("input", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.ToggleValues {
  .ToggleValuesLabel {
    padding: 0 0 $mpadding/2 0;
  }
  .ToggleValuesSwitch {
    display: flex;
    align-items: center;
    gap: $mpadding/2;
    flex-wrap: wrap;
  }
  .button {
    display: inline-flex;
    align-items: center;
    gap: $mpadding/2;
    cursor: pointer;
    background-color: $button_contrast;
    padding: $mpadding/2 $mpadding * 1.5;
    border-radius: 2pc;
    font-family: $third_font_bold;
    user-select: none;
  }
  .button:hover {
    color: $primary_color;
  }
  .button[active="true"] {
    background-color: $primary_color;
    color: #fff;
    pointer-events: none;
  }
}
</style>
