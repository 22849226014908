import { render, staticRenderFns } from "./ToggleValues.vue?vue&type=template&id=1d64de5c&scoped=true&"
import script from "./ToggleValues.vue?vue&type=script&lang=js&"
export * from "./ToggleValues.vue?vue&type=script&lang=js&"
import style0 from "./ToggleValues.vue?vue&type=style&index=0&id=1d64de5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d64de5c",
  null
  
)

export default component.exports